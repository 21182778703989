// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'

// createApp(App).use(store).use(router).mount('#app')
import ElementPlus from "element-plus"
// 导入 element-plus 的 css 样式
import 'element-plus/theme-chalk/index.css'
import { createApp } from "vue";
import App from "./App.vue";
// import 'amfe-flexible';
import store from "./store";

import Animate from "animate.css"; //字体动画
import "@/assets/css/base.css";

import lazyPlugin from "vue3-lazy";

import axios from "@/plugins/axios.js";

// 导入index.css
import "element-plus/dist/index.css";

const app = createApp(App);
app.provide("$store", store);

import router from "./router";
app.provide("$router", router);
app.provide("$axios", axios);

app
  .use(store)
  .use(router)
  .use(lazyPlugin, {
    // loading: require('img/default.jpg'), // 加载时默认图片
  })
  .use(ElementPlus)
  .use(Animate)
app.mount("#app");
