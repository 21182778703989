<template>
  <div class="div-pos footer bg-img">
    <div class="footer-show center flex">
      <div>
        <div style="padding-left: 20px">
          <img v-lazy="require('@/assets/images/home/footer1.png')"
               alt="logo"
               height="125" />
          <div style="
              color: white;
              display: inline;
              font-size: 19px;
              font-family: PingFangSC-Regular, PingFang SC;
              margin: 25px;
            ">
            点价宝
          </div>
          <div class="flex about_links"></div>
        </div>
      </div>
      <!-- <div
          style="
            color: white;
            display: inline;
            font-size: 19px;
            font-family: PingFangSC-Regular, PingFang SC;
            margin: 25px;
          "
        >
          点价宝
        </div>
        <div class="flex about_links"></div>
      </div> -->

      <div style="margin-top: 20px">
        <p>客服电话 服务时间 9:00-18:00</p>
        <h1 style="font-size: 30px">400-997-1366</h1>
        <div class="address">
          公司地址：成都市锦江区红星路3号1段国际金融中心3号楼29楼
        </div>
      </div>
    </div>
    <div class="line" />
    <div class="copyright">
      Copyright © 2023 点价宝科技（四川）有限公司
      <a target="_blank"
         href="https://beian.miit.gov.cn/"
         class="icp">
        <p>蜀ICP备2023009956号-1</p>
      </a>
    </div>
    <div class="copyright"
         style="width: 300px; margin: -12px auto 0">
      <a target="_blank"
         href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010402001466"
         class="police">
        <img style="float: left;"
             src="@/assets/images/police-icon.png" />
        <p>川公网安备 51010402001466号</p>
      </a>
    </div>
  </div>
</template>

<style src="@/assets/css/footer.css" scoped></style>