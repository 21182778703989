import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    // 动态导入Promise组件
    component: () => import("@/views/home/home"),
    // 路由元信息
    meta: {
      title: "点价宝",
      authenticated: false,
      activeMenu: "/home",
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  // linkActiveClass: "actived",
  routes,
});
// 定义路由器全局前置守卫
router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next({
      path: "/home",
      replace: true,
    });
  }
  const auth = sessionStorage.getItem("Authorization");
  // 需要认证但未认证
  if (to.meta.authenticated && !auth) {
    if (to.fullPath === "/") {
      next({
        path: "/home",
        replace: true,
      });
    } else {
      next({
        path: "/home",
        query: { redirect: to.fullPath },
        replace: true,
      });
    }
  }
  // 不需要认证或已认证
  else {
    if (to.fullPath === "/home" && auth) {
      next();
    } else {
      if (to.meta.title) {
        window.document.title = to.meta.title;
      }
      next();
    }
  }
});

export default router;
