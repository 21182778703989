<template>
  <el-container class="common-layout">
    <el-header style="background-color: #133c59d4">
      <headerComponenet></headerComponenet>
    </el-header>
    <el-main>
      <el-scrollbar>
        <router-view />
        <footerComponenet></footerComponenet>
      </el-scrollbar>
    </el-main>
  </el-container>
</template>
<script>
import headerComponenet from "components/headerComponenet.vue";
import footerComponenet from "components/footerComponenet.vue";

export default {
  name: "App",
  components: {
    headerComponenet,
    footerComponenet,
  },
  setup() {},
};
</script>
<style lang="less">
// @import url("./assets/css/base.css");
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  line-height: 1.4;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-color);
  background-color: var(--bg-color);
  direction: ltr;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color var(--el-transition-duration-fast);
  overflow: overlay;
}

.iconpark-icon {
  width: 1em;
  height: 1em;
}
.common-layout {
  height: 100vh;
  .el-header {
    position: relative;
  }
  .el-main {
    padding: 0;
  }
}
.centerContent {
  font-size: 80px;
  position: relative;
  color: #fff;
  height: 100%;
  top: -100%;
  line-height: 50vh;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
}
.imgInTitle {
  width: 100%;
  height: 100%;
}
.textTitle {
  font-size: 36px;
  padding: 20px;
}
.textContent {
  padding: 20px;
  font-size: 14px;
  line-height: 28px;
}
.titleInCard {
  color: #2165b0;
  font-size: 24px;
  font-weight: bold;
}
.contentInCard {
  display: block;
  font-size: 14px;
}
.colBtn {
  width: 150px;
  // background-color: rgb(230 237 246 / 0%);
  --el-button-bg-color: rgb(0 70 17 / 0%) !important;
  // --el-button-hover-text-color: #004fa1 !important;
  --el-button-border-color: #004fa1 !important;
  --el-button-active-bg-color: #004fa1 !important;
  // --el-button-active-text-color: #004fa1 !important;
}
</style>
