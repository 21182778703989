<template>
  <div router class="el-menu-demo" mode="horizontal" :ellipsis="false">
    <div index="/">
      <img
        v-lazy="require('@/assets/images/home/head1.png')"
        style="height: 65px"
      />
      <div
        style="
          color: white;
          display: inline;
          font-size: 19px;
          font-family: PingFangSC-Regular, PingFang SC;
          margin: 25px;
        "
      >
        点价宝
      </div>
    </div>
  </div>
</template>



<style lang="less" scoped>
.hello {
  font-size: 20px;
  div {
    color: red;
  }
}
.flex-grow,
.grow {
  flex-grow: 1;
}
.headeritem {
  font-size: 16px;
  color: #333333;
  width: 90px;
  text-align: center;
  height: 30px;
}
.logout {
  color: #409eff;
  font-size: 14px;
  width: 40px;
  cursor: pointer;
}
.login-user {
  margin-top: -2px;
  font-size: 12px;
  line-height: 25px;
}
</style>
