// import { createStore } from 'vuex'
// // 导入模块

// export default createStore({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
import { createStore } from "vuex";

let timer;
export default createStore({
  state: {
    authorization: sessionStorage.getItem("Authorization"),
    login: false, //是否显示登录界面
    mobile: false, //是否手机端显示页面
    supplyPort: false, //登录到供应端
    username: sessionStorage.getItem("username"),
    showErr: "",
    footerShow: true,
    headerShow: true,
  },
  // 获取函数
  getters: {
    getAuthorization: (state) => state.authorization,
    hasAuthorization: (state) => state.authorization !== null,
    getFooterShow: (state) => state.footerShow,
    getHeaderShow: (state) => state.headerShow,
  },
  mutations: {
    storeAuthorization(state, authorization) {
      state.authorization = authorization;
      sessionStorage.setItem("Authorization", authorization);
    },
    clearAuthorization(state) {
      for (let key in state) {
        state[key] = null;
      }
      sessionStorage.clear();
    },
    setLogin: (state, bool) => (state.login = bool),
    setSupplyPort: (state, bool) => (state.supplyPort = bool),
    //setLogined: (state, bool) => (state.logined = bool),
    setMobile: (state, bool) => (state.mobile = bool),
    setUsername: (state, value) => {
      state.username = value;
      sessionStorage.setItem("username", value);
    },
    setShowErr: (state, value) => (state.showErr = value),
    setFooterShow: (state, value) => (state.footerShow = value),
    setHeaderShow: (state, value) => (state.headerShow = value),
  },
  actions: {
    setLogin: ({ commit }, status) => commit("setLogin", status),
    setShowErr: ({ commit }, status) => {
      if (!status) {
        clearTimeout(timer);
        timer = undefined;
      }
      if (timer) return;
      commit("setShowErr", status);
      status &&
        (timer = setTimeout(() => {
          commit("setShowErr", "");
          clearTimeout(timer);
          timer = undefined;
        }, 2000));
    },
  },
  modules: {},
});
